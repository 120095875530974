'use client';

import React, { useState } from 'react';
import Navbar from '../components/PublicNavbar';
import Hero from '../components/Hero';
import ChristmasServicesBento from '../components/ChristmasServicesBento';
import PublicFooter from '../components/PublicFooter'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutUs from '../components/AboutUs'; 
import CTA from '../components/Cta';

const Home = () => {
  const [pin, setPin] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePinSubmit = () => {
    if (pin === '0368') {
      setIsAuthenticated(true);
    } else {
      alert('Pin incorect!');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-6 bg-white rounded shadow-md">
          <h2 className="mb-4 text-lg font-bold">Introduceți PIN-ul</h2>
          <input
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            className="border p-2 w-full mb-4"
          />
          <button
            onClick={handlePinSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-white">
        <Helmet>
          <title>Moș Crăciun de Închiriat - Servicii Magice de Sărbători</title>
          <meta
            name="description"
            content="Moș Crăciun de închiriat în București și zonele apropiate. Oferim Moși Crăciun cu barbă naturală pentru evenimente de neuitat!"
          />
          <meta
            name="keywords"
            content="mos craciun de inchiriat, mos craciun, mosi craciun cu barba naturala, inchiriere mos craciun, craciunite"
          />
          <meta name="robots" content="index, follow" />
          <meta name="author" content="inchriazamoscraciun.ro" />
        </Helmet>

        <Navbar />
        <main>
          <Hero />
          
          <div className="mx-auto max-w-2xl lg:max-w-7xl">
            <ChristmasServicesBento />
          </div>

          <div className="mx-auto max-w-2xl lg:max-w-7xl">
            <AboutUs /> 
          </div>

          <div className="mx-auto max-w-2xl lg:max-w-7xl">
            <CTA /> 
          </div>
        </main>

        <PublicFooter />  
      </div>
    </HelmetProvider>
  );
};

export default Home;