import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './public/pages/Home';
import Login from './public/pages/Login';
import Dashboard from './admin/pages/Dashboard';
import MosCraciunAcasa from './public/pages/MosCraciunAcasa';
import Soon from './public/pages/Soon'; // Importăm noua componentă
import PrivateRoute from './shared/PrivateRoute';
import { NotificationProvider } from './admin/components/Notification';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const maintenanceMode = true; // Setați această variabilă la true pentru a activa pagina de mentenanță

  return (
    <HelmetProvider>
      <NotificationProvider>
        <Router>
          <Routes>
            {maintenanceMode ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Soon />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/mos-craciun-acasa" element={<MosCraciunAcasa />} />
                <Route
                  path="/dashboard/*"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </Router>
      </NotificationProvider>
    </HelmetProvider>
  );
}

export default App;